<template>
  <c-main>
    <c-toolbar>
      <nuxt-link to="/dashboard" class="flex items-center gap-4">
        <c-csc-logo />
        My&nbsp;CSC
      </nuxt-link>

      <c-spacer />

      <popover-help />

      <popover-inbox />

      <c-menu v-if="profile" :custom="isMobile" :items="topMenuItems">
        <div class="flex items-center gap-2">
          <c-icon :path="mdiAccountCircle" />

          <span v-if="!isMobile" class="avatar-name">
            {{ profile.fullName }}
          </span>
        </div>
      </c-menu>

      <c-navigation-button v-if="isMobile" />
    </c-toolbar>

    <app-side-navigation />

    <c-page>
      <app-breadcrumbs />

      <slot />
    </c-page>

    <pending-actions />

    <nuxt-route-announcer />
  </c-main>
</template>

<script lang="ts" setup>
import type { CMenuOption } from '@cscfi/csc-ui';
import {
  mdiAccount,
  mdiAccountCircle,
  mdiCookie,
  mdiEmailFast,
  mdiLogout,
} from '@mdi/js';
import { Dialogs } from '~/types/enum';

const { width } = useWindowSize();

const { profile, logout } = useProfileStore();

const { addDialog } = useDialogStore();

const router = useRouter();

const topMenuItems = ref<CMenuOption[]>([
  {
    name: 'Profile',
    icon: mdiAccount,
    iconPosition: 'start',
    action: () => router.push({ name: 'Profile' }),
  },
  {
    name: 'Send feedback',
    icon: mdiEmailFast,
    iconPosition: 'start',
    action: () => addDialog(Dialogs.Feedback),
  },
  {
    name: 'Cookie Settings',
    icon: mdiCookie,
    iconPosition: 'start',
    action: () => addDialog(Dialogs.CookieConsent),
  },
  {
    name: 'Logout',
    icon: mdiLogout,
    iconPosition: 'start',
    action: () => logout(),
  },
]);

const isMobile = computed(() => width.value < 1280);

const { getMFASeed, mfa, disableMFA } = useProfileStore();

watch(
  mfa,
  (value) => {
    if (value?.url) {
      addDialog(Dialogs.Profile.MFA);
    }
  },
  { immediate: true },
);

onMounted(async () => {
  if (sessionStorage.getItem('showModal') === 'mfa') {
    sessionStorage.removeItem('showModal');
    await getMFASeed();
  } else if (sessionStorage.getItem('disableMFA') === 'true') {
    sessionStorage.removeItem('disableMFA');

    await disableMFA();
  }
});
</script>

<style>
c-page {
  --c-page-max-width: 1800px;
}
</style>
