<template>
  <nav class="pb-3" aria-label="Breadcrumb">
    <ol class="grid grid-flow-col items-center justify-start">
      <template
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb.name"
      >
        <li
          class="flex h-7 items-center"
          v-bind="{
            ...(isCurrentPage(breadcrumb.name) && { 'aria-current': 'page' }),
          }"
        >
          <c-button
            size="small"
            v-bind="{
              ...(breadcrumb.disabled && { tabindex: 0 }),
            }"
            :disabled="breadcrumb.disabled"
            text
            @click="onRedirect(breadcrumb.name)"
          >
            {{ breadcrumb.label }}
          </c-button>
        </li>

        <li class="flex items-center">
          <c-icon
            v-if="index + 1 < (breadcrumbs || []).length"
            :path="mdiChevronRight"
            :size="18"
          />
        </li>
      </template>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { mdiChevronRight } from '@mdi/js';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { Breadcrumb } from '@/types/breadcrumb';

const route = useRoute();

const router = useRouter();

const breadcrumbs = ref<Breadcrumb[] | null>(null);

const isCurrentPage = (name: string) => {
  return route.name === name;
};

const { project } = useProjectStore();

const getLabel = (
  routeData: RouteLocationNormalizedLoaded,
  breadcrumb: Breadcrumb,
) => {
  const label = breadcrumb.label
    .replaceAll('#{projectNumber}', project.value.CSCPrjNum)
    .replaceAll('#{projectTitle}', project.value.CSCPrjTitle);

  if (!breadcrumb.routeParam) {
    return label;
  }

  return `${label} ${routeData.params[breadcrumb.routeParam]}`;
};

const updateBreadcrumbs = (routeData: RouteLocationNormalizedLoaded) => {
  breadcrumbs.value = (routeData.meta.breadcrumbs || []).map((breadcrumb) => ({
    ...breadcrumb,
    label: getLabel(routeData, breadcrumb),
  }));
};

watch(route, (currentRoute) => {
  updateBreadcrumbs(currentRoute);
});

onMounted(() => {
  updateBreadcrumbs(route);
});

const onRedirect = (name: string) => {
  router.push({ name });
};
</script>
